import React from "react";
import PaymentToChina from "./PaymentToChina";

class PaymentToChinaContainer extends React.Component{

    render(): React.ReactNode{
        return (
            <PaymentToChina 
            
            />
        )
    }
}

export default PaymentToChinaContainer;