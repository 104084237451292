import ReactDOM from 'react-dom/client';
import './styles/main.scss';
import App from './App';
import { StoreContext } from 'storeon/react'
import { storeon } from './storeStoreon/storeon';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <StoreContext.Provider value={storeon}>
      <App />    
    </StoreContext.Provider>
);

