import React from 'react'

function BackgrounColor() {
  return (
    <div style={{
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(16,16,16,.6)'
    }}></div>
  )
}

export default BackgrounColor