import insta from './about/instagram.svg'
import telega from './about/telegram_2.svg'
import youtube from './about/youtube_2.svg';
import facebook from './about/facebook.svg';
import whatsapp from './about/whatsapp.svg';
import rutube from './about/rutube.svg';
import lineGray from './about/line.svg';
import plus from './about/plus.svg';
import phone from './about/phone_icon.svg';
import mail from './about/mail_icon.svg';
import quota from './about/quota.svg'
import check from './about/check-all.svg'

import location from './about/location.svg'
import arrowLeft from './about/arrow-left-thin.svg';
import arrowRight from './about/arrow-right-thin.svg';
import line from './about/line-one-horizontal-lined.svg';
import aboutPage from './about/breadcumb-bg-2.jpg'
import imageFone from './about/shape_3.png'
import avatar from './about/avatar-business-human-svgrepo-com.svg'


const logo = "https://back.cargo8652.com/media/content/image/page/2024/08/21/cargo-logo-734x367_Sbbbwbc.png";

export {
    insta,
    telega,
    youtube,
    facebook,
    whatsapp,
    rutube,
    lineGray, //?
    plus,    
    phone,
    mail,
    quota, 
    check,
    
    location,
    arrowLeft,
    arrowRight,
    line,
    aboutPage,
    imageFone,
    avatar,

    logo,
}