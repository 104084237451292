import React from 'react'
import IntroductionContainer from '../../../Views/Introduction/IntroductionContainer'
import IntroductionWrap from '../../../Views/Introduction/IntroductionWrap'
import IntroductionGrid from '../../../Views/Introduction/IntroductionGrid'
import Container from '../../../Views/Block/Container'
import IntroductionIconContainer from '../../../Views/Introduction/IntroductionIconContainer'
import TextTitle from '../../../Views/Text/TextTitle'
import Text from '../../../Views/Text/Text'
import { IPropsBloks, IPropsDataPage } from '../../../@types/common'
import Offset from '../../../Views/Offset/Offset'

interface IProps {
    infoBlock: IPropsDataPage
}


const IntroductionCards: React.FC<IProps> = ({
    infoBlock
}: IProps) => {
    return (
        <Container
            size={1400}
            center
        >
            <IntroductionContainer>
                {
                    infoBlock?.blocks && infoBlock.blocks.map((item: IPropsBloks) => (
                        <IntroductionWrap key={item.id}>
                            <IntroductionGrid>
                                <IntroductionIconContainer>
                                    <Text>{item.title_2}</Text>
                                </IntroductionIconContainer>
                                <Offset mb={30} />
                                <TextTitle type='h3' addClass='text__title-introduction'>{item.title_1}</TextTitle>
                                <Text addClass="text__text-introduction">{item.description}</Text>
                            </IntroductionGrid>
                        </IntroductionWrap>
                    ))
                }
            </IntroductionContainer>
        </Container>
    )
}

export default IntroductionCards